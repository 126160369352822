const adjectives = [
  "ZGK",
  "ZNT",
  "HMB",
  "DMG",
  "KNK",
  "LFQ",
  "MPV",
  "XFN",
  "CYR",
  "YLT",
  "VMB",
  "GDG",
  "RGK",
  "PCP",
  "GDH",
  "PKS",
  "HBV",
  "QYF",
  "RDG",
  "PCZ",
  "FMF",
  "BNK",
  "CSB",
  "PYF",
  "BCX",
  "XFF",
  "LKF",
  "BDV",
  "KBV",
  "FGV",
  "YBZ",
  "FMC",
  "LBF",
  "XYC",
  "YFB",
  "CFP",
  "XXT",
  "JBT",
  "XRZ",
  "QSV",
  "DJN",
  "TTC",
  "JLG",
  "SGR",
  "BGY",
  "SSF",
  "BGF",
  "ZTV",
  "YDX",
  "PGT" 
];

const nouns = [
"064",
"483",
"564",
"035",
"936",
"392",
"333",
"517",
"068",
"502",
"653",
"707",
"780",
"019",
"137",
"024",
"350",
"673",
"153",
"990",
"402",
"771",
"715",
"446",
"249",
"825",
"477",
"149",
"050",
"480",
"148",
"670",
"909",
"991",
"092",
"647",
"561",
"659",
"719",
"236",
"761",
"639",
"100",
"559",
"032",
"987",
"929",
"531",
"551",
"354"
]; 

export function generateHubName() {
  const randItem = set => {
    const s = set[Math.floor(Math.random() * set.length)];
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return `${randItem(adjectives)} ${randItem(adjectives)} ${randItem(nouns)}`;
}
